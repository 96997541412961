import React from "react";
import Page from "../../pages/Page/Page";
import { RegisterInterestForm } from "grainger-component-library";
import {
  SALESFORCE_ESTATE,
  SALESFORCE_FIELD_IDS,
  SALESFORCE_FIELD_VALUES,
  GOOGLE_RECAPTCHA_KEY,
} from "../../config/constants";
import formattedStringToDictionary from "../../utils/formattedStringToDictionary";

export default function Contact(props) {
  const salesforceFieldIds = formattedStringToDictionary(
    ",",
    "=",
    SALESFORCE_FIELD_IDS
  );
  const salesforceFieldValues = formattedStringToDictionary(
    ",",
    "=",
    SALESFORCE_FIELD_VALUES
  );

  const formComp = (designComponent) => (
    <>
      {designComponent && (
        <designComponent.component {...designComponent.props} />
      )}
      <div className="gr-grainger-contact">
        <div className="gr-grainger-form">
          <div className="gr-grainger-form__form">
            {window.location.host ===
              "gatehouseapartments.graingertest.com" && (
              <h1 style={{ color: "red" }}>
                {" "}
                PRODUCTION INSTANCE OF SFDC CONTACT FORM{" "}
              </h1>
            )}
            <RegisterInterestForm
              title="Register your interest and we'll be in touch soon"
              estate={SALESFORCE_ESTATE}
              recaptchaKey={GOOGLE_RECAPTCHA_KEY}
              affiliates={[
                "Grainger website",
                "Social Media",
                "Rightmove",
                "Zoopla",
                "Refer a Friend",
                "I already live in a Grainger Property",
                "Google",
                "Magazine/ News Article",
                "Word of mouth",
                "Signage outside of Property",
                "Transport Advertising",
                "Building Banner",
                "Radio",
                "Clippers Quay Website",
                "Hawkins & George Website",
                "Solstice Apartments Website",
                "Millet Place Website",
                "Brook Place Website",
                "Gatehouse Apartments Website",
              ]}
              salesforceFieldIds={salesforceFieldIds}
              salesforceFieldValues={salesforceFieldValues}
            />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <Page {...props}>
      {(pageComponents) => {
        const comp = Object.values(pageComponents)[0];
        if (comp && comp.component) {
          return formComp(comp);
        }
        return formComp();
      }}
    </Page>
  );
}
